import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './modules/login/login.module';
import { MsalModule} from '@azure/msal-angular';
import { OAuthSettings } from './services/oauth';
import { HeaderComponent } from './components/header/header.component';
import {AngularMaterialModule} from './modules/angular-material/angular-material.module';
import {JwtInterceptor} from './services/jwt.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularMaterialModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoginModule,
    MsalModule.forRoot({clientID: OAuthSettings.appId, redirectUri: OAuthSettings.redirectUri})
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
