import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdvertiserService {


  constructor(private httpClient:HttpClient) { }

  public list(id_partner: number, page: number, count: number, sort_column: string, sort_direction: string, filter: string):Observable<any>
  {
    let params = new HttpParams().set("page", String(page)).set("count", String(count));

    if (sort_column) {
      params = params.set("sort_column", sort_column);

      if (sort_direction) {
        params = params.set("sort_direction", sort_direction);
      }
    }

    if (filter) {
      params = params.set("filter", filter);
    }

    return this.httpClient.get("api/publicist/list/" + id_partner, { params: params });
  }

  public get(advertiser_id:number):Observable<any>
  {
    return this.httpClient.get('api/publicist/' + advertiser_id);
  }

  public change(body: any):Observable<any>
  {
    let httpOptions = { headers: new HttpHeaders({
        'Content-Type' : 'application/json'
      })};

    return this.httpClient.post('api/publicist/change/', body, httpOptions);
  }

  public add(body: any): Observable<any>
  {
    let httpOptions = { headers: new HttpHeaders({
        'Content-Type' : 'application/json'
      })};

    return this.httpClient.post('/api/publicist/add/', body, httpOptions);
  }

  public edit(body: any): Observable<any>
  {
    let httpOptions = { headers: new HttpHeaders({
        'Content-Type' : 'application/json'
      })};

    return this.httpClient.post('/api/publicist/edit/', body, httpOptions);
  }

}
