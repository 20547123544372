import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuardService} from './services/auth-guard.service';

const routes: Routes = [
{
  path: '',
  loadChildren: './modules/client/client.module#ClientModule',
  canLoad: [ AuthGuardService ]
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
