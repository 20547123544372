import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {AdvertiserService} from "../../services/advertiser.service";
import {PartnerService} from "../../services/partner.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public path: any;

  constructor(
    private authService: AuthService,
    private advertiserService: AdvertiserService,
    private partnerService: PartnerService,
    private router: Router) {
  }

  private async createPath(url){

    const pattern = new RegExp('mass-edit|clients|file','gm');
    const idPattern = new RegExp('[0-9]+','gm');

    return await new Promise(resolve => {
      if(pattern.test(url)){
        if(url.match(pattern)[0] === 'clients')
        {
          this.partnerService.get(+url.match(idPattern)[0]).subscribe(partner => {
            resolve({ url_partner : url, partner : { name : partner.partner_name, id : partner.id_partner } });
          })
        } else if(url.match(pattern)[0] === 'file') {

          this.partnerService.get(+url.match(idPattern)[0]).subscribe(partner => {
            this.advertiserService.get(+url.match(idPattern)[1]).subscribe(advertiser => {
              resolve( { url_partner : '/clients/' + partner.id_partner, url : url, partner : { name : partner.partner_name, id: partner.id_partner }, advertiser : { name : advertiser.name, id : advertiser.id_advertiser } });
            })
          })

        } else if(url.match(pattern)[0] === 'mass-edit') {
          this.partnerService.get(+url.match(idPattern)[0]).subscribe(partner => {
            this.advertiserService.get(+url.match(idPattern)[1]).subscribe(advertiser => {
              resolve( { url_partner : '/clients/' + partner.id_partner, url: '/file/' + partner.id_partner + '/' + advertiser.id_advertiser, partner : { name: partner.partner_name, id: partner.id_partner }, advertiser : { name : advertiser.name, id : advertiser.id_advertiser } });
            })
          })
        }
      }
      else resolve({})

    });
  }

  ngOnInit() {
    this.rPath();
  }

  private async rPath(){
    this.path = await this.createPath(this.authService.redirectUrl);
    // console.log(this.path);

    this.router.events.subscribe(async (e) => {
      if (e instanceof NavigationEnd) {
        this.path = await this.createPath(e.url);
        // console.log(this.path);
      }
    })
  }

  logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/login']);
    });
  }


}
