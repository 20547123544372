import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(private httpClient:HttpClient) { }

  public list(page: number, count: number, sort_column: string, sort_direction: string, filter: string):Observable<any>
  {
    let params = new HttpParams().set("page", String(page)).set("count", String(count));

    if (sort_column) {
      params = params.set("sort_column", sort_column);

      if (sort_direction) {
        params = params.set("sort_direction", sort_direction);
      }
    }

    if (filter) {
      params = params.set("filter", filter);
    }

    return this.httpClient.get("api/partner/list", { params: params });
  }

  public get(id_partner : number):Observable<any>
  {
    return this.httpClient.get('api/partner/' + id_partner);
  }

  public getAdvertisersFromPartner(partner_id: number):Observable<any>
  {
    return this.httpClient.get('api/partner/getAdvertisersFromPartner/' + partner_id);
  }

  public getInventorySourceList(partner_id: number):Observable<any>
  {
    return this.httpClient.get('api/partner/getInventorySourceList/' + partner_id);
  }

  public getUserList(partner_id: number):Observable<any>
  {
    return this.httpClient.get('api/partner/getUserList/' + partner_id);
  }

  public delete(id_partner : number):Observable<any>
  {
    return this.httpClient.get('api/partner/delete/' + id_partner);
  }

  public add(body: any): Observable<any>
  {
    let httpOptions = { headers: new HttpHeaders({
        'Content-Type' : 'application/json'
      })};

    return this.httpClient.post('/api/partner/add/', body, httpOptions);
  }

  public edit(body: any): Observable<any>
  {
    let httpOptions = { headers: new HttpHeaders({
        'Content-Type' : 'application/json'
      })};

    return this.httpClient.post('/api/partner/edit/', body, httpOptions);
  }


}
